<template>
  <div class="row mx-0 justify-content-center height">
    <div class="col-11 col-sm-9 col-md-8 col-lg-7 col-xl-6 col-xxl-6 h-100 p-0 relative box_shadow">
      <div class="imageSlider-item height">
        <div class="h-100" ref="theImage">
          <Image :imageName="project.imageUrl" :width="theImageWidth" :height="theImageHeight" theClass="d-block w-100 slider_img" />
          <div class="price bold position-absolute start-50 translate-middle-x" v-if="project && (project.fixed || project.suggestedPrice)">
            {{currency}}{{project.cost}} {{project.costType.desciption}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Image: defineAsyncComponent(() => import('@/components/Image.vue'))
  },
  name: 'Project Individual Image',
  props: ['project', 'currency'],
  data () {
    return {
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
  },
  methods: {
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    }
  }
}
</script>

<style scoped>
  .imageSlider-item {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .box_shadow {
    box-shadow: 0px 15px 24px 0px rgb(0 0 0 / 21%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 15px 15px 15px 15px;
    padding: 45px 45px 45px 45px;
    border-radius: 15px;
  }
  .height {
    height: 177px;
  }
  .slider_img  {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 22px;
  }
  .price {
    background-color: var(--green-color-dark);
    padding: 6px 15px;
    color: var(--gold-color);
    border-radius: 37px;
    width: auto;
    font-size: 12px;
    bottom: 25px;
  }
  @media (min-width: 992px) {
    .height {
      height: 325px;
    }
    .slider_img  {
      border-radius: 15px;
    }
    .price {
      padding: 7px 17px;
      font-size: 23px;
      bottom: 45px;
    }
  }
</style>
